import React from 'react';
import {useLayoutEffect} from 'react';
import '../App.css';
import '../fonts.css';
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";


function PCCTourContent() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
    
    <div className='page'>

        <div className='parchment-box'>
            <h1 className='itin-title'>Plymouth & Cape Cod Tour</h1>
        </div>
        
        <div className='blue-box' id='beige'>

            <div className='picture-text-container'>
                    <img id='minuteman' src={require("../images/pilgrims-glass.png")} />
                <div className='right-column-list' id='itin-column'>
                <h2 className='subheading3' id='center-phone'>Itinerary for the Day</h2>
                <h2 className='subheading4' id='margin-top'>CAPE COD</h2>

                    <ul className='details-list'>
                        <li>Cape Cod Canal (managed by the U.S. Army Corps of Engineers)</li>
                        <li>Cape Cod Canal Visitor’s Center and Museum</li>
                        <li>Town of Sandwich</li>
                        <li>Sandwich Glassworks Factory Museum (admission included)</li>
                        <li>1654 Dexter Grist Mill (admission included) - add back this bullet</li>
                        <li>Historic King’s Highway</li>
                        <li>An Atlantic barrier beach</li>
                    </ul>

                    <h2 className='subheading4'>PLYMOUTH (EST 1620)</h2>

                    <ul className='details-list'>
                        <li>Beautiful Plymouth Harbor and Pilgrim Memorial State Park</li>
                        <li>Mayflower II* (admission included)</li>
                        <li>Plymouth Rock</li>
                        <li>Iconic statues of William Brewster, the Pilgrim Maiden and Massasoit (Great Sachem of the Wampanoags)</li>
                        <li>Leyden Street, oldest intact settlement Street in America, and location of the Mayflower Society
                        Churches of the Pilgrimage</li>
                        <li>Forefathers Monument, the world’s largest solid granite monument</li>
                        <li>Walk the floors of the only known house where a Pilgrim spent time visiting family in 1667. (Available Thursday through Monday)</li>
                    </ul>

                </div>
            </div>

            <div className='timing'>
                <hr />
                <p>Pickups for this tour start at 8:05 AM, and the tour length is approximately 9 hours.</p>
                        <p>Adults: $159</p>
                        <p>Children: $109</p>
                <hr />
            </div>
        </div>

        <div className='drawn-box'>
          <div className='drawn-content'>
            <h1 className='itin-description-title'>DISCOVER THE BEAUTY OF PLYMOUTH AND CAPE COD</h1>
              <div className='justify'>

                  <p>The company that brings you the unforgettable Lexington and Concord, American Revolution tour is now presenting a deluxe Cape Cod tour mixing sun, fun, adventure, and history. </p>

                <p>Cape Cod evokes images of fishing villages, baseball, summer beaches, fresh seafood and lobster, lighthouses, and cottages. Your voyage begins crossing the historic Sagamore Bridge over the Cape Cod Canal (17.4 miles long) to our first destination, the Canal Museum. Learn about this remarkable feat of engineering by the Army Corps of Engineers and how it was built to bypass the treacherous “graveyard of the Atlantic.” Then we’ll proceed to the postcard perfect Town of Sandwich, Cape Cod’s oldest, settled by the Pilgrims. The town motto is <em>Post tot Naufracia Portus, </em>“after so many shipwrecks, a haven.</p>

                <p>Sandwich resembles a quaint village back in old England, and one common theme with early settlements is the town grist mill. You’ll visit the working Dexter Grist Mill built in 1654. Perhaps purchase some gourmet flour like chefs around the country (Tuesdays and Wednesdays). Then, enjoy the glass exhibits at the nearby Sandwich Glass Museum, home to a world famous collection of 5,000 pieces of 1800s pressed glass. Admire the incredible display of glowing uranium glass goblets or purchase a souvenir sun catcher from the gift shop. You’ll have plenty of time to peruse the downtown shops or order tea and crumpets at one of several bakeries.</p>

                <p>Moving on, you will view the spectacular Sandwich Boardwalk that stretches to the Atlantic, which allowed farmers to harvest salt hay for livestock in the 1800s. We will then stop for lunch (price not included) with the locals at a landmark restaurant.</p>

                <p>Traveling along the Old King’s Highway, past restored homes from the 1700s and 1800s, the next stop is the Town of Barnstable’s Sandy Neck Beach, a spectacular barrier beach with dunes and marshes. Get your feet wet on this seven-mile beach before we head back over the canal to Plymouth!</p>

                <p>Arriving at “America’s Hometown” and Plymouth Harbor, board the restored Mayflower II for an experience of what it was like for those Pilgrims and the Strangers to cross thousands of miles of open ocean. Take a picture of a lifetime of Plymouth Rock, where those brave souls landed, figures like Mary Chilton, John and Priscilla Alden, John Howland, and William Bradford, seeking to establish a tenuous foothold in the New World. You may even discover that you’re a descendant of the passengers who had children, part of a progeny today numbering about 35,000,000 across the globe.</p>

                <p>Pose for a picture next to Plymouth Rock, marking the spot where those brave souls landed: Mary Chilton, John and Priscilla Alden, John Howland, and William Bradford. </p>

                <p>Follow your guide up to Cole’s Hill overlooking the beautiful harbor, and read the names engraved on a spectacular marble sarcophagus of those men and women who planted the seeds of freedom. </p>

                <p>Around the corner is Leyden Street, the oldest continuously occupied street in America.  Walk in the footsteps of Pilgrims, past old sea captains’ homes, to the Church of the Covenant, whose Separatist roots stretch back to Scrooby, England.</p>

                <p>Walk the floors of the only known house where a Pilgrim spent time visiting family in 1667. (Available Thursday through Monday)</p>

                <p>The final stop on your Plimoth adventure is the nation’s Forefathers Monument site, the largest solid granite monument in North America. It is dedicated to the Pilgrims’ focus on Faith, Morality, Law, Education, and Liberty as the bedrock of our Western Civilization. </p>

                <p>After a day walking in the footsteps of history, relax and enjoy a comfortable ride back to Boston carrying cherished memories of a lifetime.</p>
            
            </div>

            <Link to="/tour-details" className='center'>
                        <h2 className='details-button'>MORE DETAILS</h2>
                </Link>
          </div>
        </div>
    </div>

    );
}

export default PCCTourContent;