import React from 'react';
import '../App.css';
import '../fonts.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

function AboutContent() {
    return (

    <>
    <div className="parallax-about">
            <div id="parallax-box" className='overlay'>
                <div className="container">
                    <h2>ABOUT US</h2>
                    <h2 className='subtitle'> Great Boston Tours brings lovers of history on a deep dive into the true start of the American Revolution.</h2>
                </div>
            </div>
    </div>
    
    <div className='page'>
        <div className='parchment-box'>

            <div className='picture-text-container' id='text-middle'>
                <div className='picture'>
                    <img id="bunker-hill" src={require("../images/bunker-hill.png")} />
                </div>
                <div className='right-column-text'>
                    <p>
                    Operated by a local American history expert, our tours offer a level of information and attention to detail that simply <strong>cannot be experienced anywhere else in the Boston area.</strong>
                        </p>
                    <p>
                    With sites in Boston, Cambridge, Lexington, Concord, Charlestown, Quincy, Plymouth and Cape Cod, you’ll experience the events that led to the American Revolution and other events that led to the creation of an independent nation.
                    </p>
                </div>
            </div>
        </div>

        <div className='blue-box'>

            <div className='picture-text-container' id='text-middle'>
                <div className='left-column-text'>
                   
                  <h2 className='subheading3'> OUR STAFF </h2>

                    <p>Our founder, Richard, is a history expert—and also a seasoned tour operator.</p>
                    <p>With over 18 years of experience operating tours in the Boston area, Richard combines his deep knowledge of history with his understanding of comfort and quality to provide the most informative revolutionary tour experience available.</p>
                    <p>Our drivers are all insured and deeply knowledgeable of the history and geography of Boston.</p>

                </div>
                
                <div className='picture'>
                    <img id="cookware" src={require("../images/cookware.jpg")} />
                </div>
            </div>

        </div>

        <div className='red-box'>

        <div className='picture-text-container' id='text-middle'>
                <div className='picture' id='bus'>
                    <img id="bunker-hill" src={require("../images/tour-bus.png")} />
                </div>
                <div id='top-padding'>
                <div className='right-column-text' id='centered'>
                <h2 className='subheading3'> CONTACT US </h2>
                  <div id='contact-us'>
                    <p>1-888-346-5634</p>
                    <p>greatbostontours@gmail.com</p>
                    <p>Boston, MA, USA</p>
                  </div>
                    <iframe id='google-map'
                            title="google map"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d188762.55275765833!2d-71.17770338083741!3d42.33367712249153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3652d0d3d311b%3A0x787cbf240162e8a0!2sBoston%2C%20MA!5e0!3m2!1sen!2sus!4v1731365899989!5m2!1sen!2sus"
                            width="280"
                            height="300"
                            style={{ border: 0 }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        >
                        </iframe>
                </div>
    </div>
        </div>

        </div>
    </div>
    </>
);
}

export default AboutContent;