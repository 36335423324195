import React from 'react';
import './App.css';
import './fonts.css';
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";

function Footer() {

    return (

        <>
        <div id="footer-wrapper">
            <table class="table-footer">
                <tr>
                    <th>
                        <img id="brand" src={require("./images/footer/Logo-GBT.png")} />
                    </th>
                    <th>
                        <a href="https://www.meetboston.com/listing/great-boston-tours/26906/" target="_blank">
                        <img id="brand2" src={require("./images/footer/MeetBoston.png")} />
                        </a>
                    </th>

                        <th id="hide-mobile">
                            <img id="brand3" src={require("./images/footer/TripAdvisor-Boston.png")} />
                        </th>
                        <th id="hide-mobile">
                            <a href="https://www.bbb.org/us/ma/scituate/profile/tour-operators/great-boston-tours-0021-556183#sealclick" target="_blank">
                            <img id="brand4" src={require("./images/footer/BBB.png")} />
                            </a>
                        </th>
                        
                </tr>
                
                <tr id="hide-comp">
                    <td id="table-footer2">
                        <img id="brand3" src={require("./images/footer/TripAdvisor-Boston.png")} />
                    </td>
                    <a href="https://www.bbb.org/us/ma/scituate/profile/tour-operators/great-boston-tours-0021-556183#sealclick" target="_blank">
                    <td id="table-footer3">
                        <img id="brand4" src={require("./images/footer/BBB.png")} />
                    </td>
                    </a>
                </tr>
            </table>
        </div>

        <div id="footer-wrapper2">
                <div id="footer2">
                    <ul><h1>
                        <li>1-888-346-5634</li>
                        <li id='footer-email'>richard@greatbostontours.com</li>
                      <div className='row'>
                        <a href="https://www.facebook.com/greatbostontours" target="_blank">
                        <li>
                                <img id="social" src={require("./images/footer/facebook-icon.png")} />
                        </li>
                        </a>
                        <a href="https://www.instagram.com/greatbostontours/" target="_blank">
                        <li>
                            <img id="social" src={require("./images/footer/insta-icon.png")} />
                        </li>
                        </a>
                      </div>
                    </h1>
                    </ul>
                </div>
            </div><div id="footer-wrapper3">
                <p>&copy; 2025 Great Boston Tours All Rights Reserved</p>
                <br />
                <Link to="/privacy"> Privacy Policy </Link>
            </div></>

    );
}

export default Footer;