import React from 'react';
import {useLayoutEffect} from 'react';
import './App.css';
import './fonts.css';
import Hamburger from './Hamburger';
import ContactTop from './ContactTop';
import NavMenu from './NavMenu';
import PrivacyContent from './components/PrivacyContent';
import Footer from './Footer';

function Privacy() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
    <div>
        <Hamburger />
        <NavMenu />
        <ContactTop />
        <PrivacyContent />
        <Footer />    
    </div>
    );
}

export default Privacy;